import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import AppContext from './store/AppContext';
import './App.css';
import Spinner from './components/UI/Spinner';
import PickCountryApp from './components/PickCountryForms/PickCountryApp';
import CookieConsent from './components/CookieConsent/CookieConsent';
import { useCookies } from 'react-cookie';

// import RootElement from './components/RootElement/RootElement';
// import Privacy from './components/Privacy/Privacy';
// import TermsOfUse from './components/TermsOfUse/TermsOfUse';
// import MobileOperators from './components/Roles/MobileOperators';
// import TowerCos from './components/Roles/TowerCos';
// import Owners from './components/Roles/Owners';
// import LegalServices from './components/Roles/LegalServices';
// import Consultants from './components/Roles/Consultants';
// import PickCountryLogin from './components/PickCountryForms/PickCountryLogin';
// import PickCountryRegister from './components/PickCountryForms/PickCountryRegister';
// import PageNotFound from './components/PageNotFound/PageNotFound';

const RootElement = lazy(() => import('./components/RootElement/RootElement'));
const Privacy = lazy(() => import('./components/Privacy/Privacy'));
const TermsOfUse = lazy(() => import('./components/TermsOfUse/TermsOfUse'));
const MobileOperators = lazy(() =>
  import('./components/Roles/MobileOperators')
);
const TowerCos = lazy(() => import('./components/Roles/TowerCos'));
const Owners = lazy(() => import('./components/Roles/Owners'));
const LegalServices = lazy(() => import('./components/Roles/LegalServices'));
const Consultants = lazy(() => import('./components/Roles/Consultants'));
const PickCountryLogin = lazy(() =>
  import('./components/PickCountryForms/PickCountryLogin')
);
const PickCountryRegister = lazy(() =>
  import('./components/PickCountryForms/PickCountryRegister')
);
const PageNotFound = lazy(() =>
  import('./components/PageNotFound/PageNotFound')
);

function App() {
  const [cookie] = useCookies(['cookieConsentTB']);

  useEffect(() => {
    // useEffect to add show-nav class once the page loads.
    const timeOutId = setTimeout(() => {
      document.querySelector('#header')?.classList.add('show-nav');
    }, 200);

    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  // Hide/Show navbar on scroll
  var lastScrollY = 0;
  document.addEventListener(
    'scroll',
    () => {
      const header = document.querySelector('#header');
      if (document.querySelector('#secondary-nav') === null) {
        setTimeout(() => {
          if (lastScrollY > window.scrollY) {
            header?.classList.add('show-nav');
          } else {
            header.classList.remove('show-nav');
          }
          lastScrollY = window.scrollY;
        });
      } else {
        const secondaryNav = document.querySelector('#secondary-nav');
        setTimeout(() => {
          if (lastScrollY > window.scrollY) {
            header?.classList.add('show-nav');
            secondaryNav?.classList.add('show-secondary-nav');
          } else {
            header.classList.remove('show-nav');
            secondaryNav.classList.remove('show-secondary-nav');
          }
          lastScrollY = window.scrollY;
        });
      }
    },
    800
  );

  return (
    <AppContext>
      <Suspense fallback={<Spinner />}>
        <Navbar />
        {!cookie.cookieConsentTB && <CookieConsent />}
        <main>
          <Routes>
            <Route path='/' element={<RootElement />} />
            <Route path='/mobile-operators' element={<MobileOperators />} />
            <Route path='/tower-cos' element={<TowerCos />} />
            <Route path='/owners' element={<Owners />} />
            <Route path='/legal-services' element={<LegalServices />} />
            <Route path='/consultants' element={<Consultants />} />
            <Route path='/pick-country-app' element={<PickCountryApp />} />
            <Route path='/pick-country-login' element={<PickCountryLogin />} />
            <Route
              path='/pick-country-register'
              element={<PickCountryRegister />}
            />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/terms-of-conditions' element={<TermsOfUse />} />

            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </main>
        <Footer />
      </Suspense>
    </AppContext>
  );
}

export default App;
