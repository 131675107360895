import logo from '../../assets/logo/TowerBuddy.png';
import Container from '../UI/Container';
import './Footer.css';
import Svg from '../UI/Svg';
import {
  FaXTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../store/AppContext';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const { setBtnActive } = useAppContext();

  return (
    <footer>
      <section id='footer'>
        <Svg color1={'#e6e6e6'} color2={'#666666'} color3={'#1A1A1A'} />
        <Container className='container-footer pd-btm-sm'>
          <div className='footer-container'>
            <div className='link-container1'>
              <div className='towerbuddy-logo-container'>
                <img
                  className='towerbuddy-logo'
                  src={logo}
                  alt='tower buddy logo'
                />
              </div>
              <div className='contact'>
                <p>Contact</p>
                <nav>
                  <ul className='nav-lists'>
                    <li className='nav-list'>
                      <Link
                        className='nav-link'
                        to='tel:+97143793299'
                        aria-label='+97143793299'
                      >
                        +97143793299
                      </Link>
                    </li>
                    <li className='nav-list'>
                      <Link
                        className='nav-link'
                        to='mailto:info@i4sight.net'
                        aria-label='info@i4sight.net'
                      >
                        info@i4sight.net
                      </Link>
                      <span>|</span>
                      <Link
                        className='nav-link'
                        to='mailto:info@towerbuddy.tel'
                        aria-label='info@towerbuddy.tel'
                      >
                        info@towerbuddy.tel
                      </Link>
                    </li>
                    <li className='nav-list'>
                      <Link
                        className='nav-link'
                        to='https://www.google.com/search?q=DTEC%2C+SILICON+OASIS+DUBAI%2C+UAE&oq=DTEC%2C+SILICON+OASIS+DUBAI%2C+UAE&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzE1N2owajGoAgCwAgA&sourceid=chrome&ie=UTF-8'
                        aria-label='DTEC, Silicon Oasis Dubai, UAE'
                      >
                        DTEC, Silicon Oasis Dubai, UAE
                      </Link>
                    </li>
                    <li className='nav-list'>
                      <div className='social-links-container'>
                        <Link
                          className='nav-link'
                          to='https://www.instagram.com/towerbuddy2007/'
                          aria-label='instagram handle'
                        >
                          <FaInstagram className='social-icon' />
                        </Link>
                        <Link
                          className='nav-link'
                          to='https://www.facebook.com/profile.php?id=100090496861226'
                          aria-label='facebook handle'
                        >
                          <FaFacebookF className='social-icon fb' />
                        </Link>
                        <Link
                          className='nav-link'
                          to='https://www.linkedin.com/company/towerbuddy/'
                          aria-label='linkedin handle'
                        >
                          <FaLinkedinIn className='social-icon' />
                        </Link>
                        <Link
                          className='nav-link'
                          to='https://x.com/TowerBuddy_Tel'
                          aria-label='twitter handle'
                        >
                          <FaXTwitter className='social-icon' />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className='quick-links'>
                <p>Quick Links</p>
                <nav>
                  <ul className='nav-lists'>
                    <li className='nav-list'>
                      <Link
                        className='nav-link'
                        to='/#home'
                        onClick={() => {
                          document.title = 'TowerBuddy';
                          setBtnActive('home');
                          document.querySelector('#home')?.scrollIntoView?.();
                        }}
                      >
                        Home
                      </Link>
                    </li>

                    <li className='nav-list'>
                      <Link
                        className='nav-link'
                        to='/#our-offerings'
                        onClick={() => {
                          document.title = 'TowerBuddy » Product';
                          setBtnActive('product');
                          document
                            .querySelector('#our-offerings')
                            ?.scrollIntoView?.();
                          setTimeout(() => {
                            var windowY = window.pageYOffset;
                            window.scrollTo(0, windowY - 84);
                          }, 800);
                        }}
                      >
                        Product
                      </Link>
                    </li>
                    <li className='nav-list'>
                      <Link
                        className='nav-link'
                        to='/#about'
                        onClick={() => {
                          document.title = 'TowerBuddy » About';
                          document
                            .querySelector('#count-on-us')
                            ?.scrollIntoView?.();
                          setTimeout(() => {
                            setBtnActive('about');
                            var windowY = window.pageYOffset;
                            window.scrollTo(0, windowY - 84);
                          }, 800);
                        }}
                      >
                        About
                      </Link>
                    </li>
                    <li className='nav-list'>
                      <Link
                        className='nav-link'
                        to='/#demo'
                        onClick={() => {
                          document.title = 'TowerBuddy » Demo';
                          setBtnActive(null);
                          document.querySelector('#demo')?.scrollIntoView?.();
                          setTimeout(() => {
                            var windowY = window.pageYOffset;
                            window.scrollTo(0, windowY - 84);
                          }, 800);
                        }}
                      >
                        Demo
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className='link-container2'>
              <div className='privacy-terms-container'>
                <p className='nav-link-text'>Copyright {year} i4SIGHT</p>
                <span>|</span>
                <Link
                  className='nav-link'
                  to='/privacy'
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and
                  }}
                >
                  Privacy Policy
                </Link>
                <span>|</span>
                <Link
                  className='nav-link'
                  to='/terms-of-conditions'
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and
                  }}
                >
                  Terms & Condition
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
