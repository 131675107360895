import { useState } from 'react';
import './MobileNavbar.css';
import logo from '../../assets/logo/TowerBuddy.png';
import Container from '../UI/Container';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../store/AppContext';
import { IoMenu, IoClose } from 'react-icons/io5';

const MobileNavbar = () => {
  const { btnActive, setBtnActive } = useAppContext();
  const [isbtnClicked, setBtnClick] = useState(false);

  return (
    <Container className='container-navbar-mobile'>
      <div className='container-nav-mobile'>
        <Link to={'/'}>
          <img className='logo-mobile' src={logo} alt='tower buddy logo' />
        </Link>
        {!isbtnClicked ? (
          <IoMenu
            className='mobile-nav-icons'
            onClick={() => {
              setBtnClick(true);
            }}
          />
        ) : (
          <IoClose
            className='mobile-nav-icons'
            onClick={() => {
              setBtnClick(false);
            }}
          />
        )}
        <nav
          className={`nav-container-mobile ${
            !isbtnClicked ? ['mobile-nav-hidden'] : null
          }`}
        >
          <ul className='navlists-mobile'>
            <li className='navlist-mobile'>
              <Link
                className={`navlink-mobile ${btnActive === 'home' && 'active'}`}
                to='/#home'
                onClick={() => {
                  document.title = 'TowerBuddy';
                  setBtnActive('home');
                  document.querySelector('#home')?.scrollIntoView();
                  setTimeout(
                    () =>
                      document
                        .querySelector('#header')
                        .classList.add('show-nav'),
                    800
                  );
                  setBtnClick(!isbtnClicked);
                }}
              >
                Home
              </Link>
            </li>
            <li className='navlist-mobile'>
              <Link
                className={`navlink-mobile ${
                  btnActive === 'product' && 'active'
                }`}
                to='/#our-offerings'
                onClick={() => {
                  document.title = 'TowerBuddy » Product';
                  setBtnActive('product');
                  document.querySelector('#our-offerings')?.scrollIntoView();
                  setTimeout(() => {
                    let windowY = window.pageYOffset;
                    window.scrollTo(0, windowY - 62);
                    document.querySelector('#header').classList.add('show-nav');
                  }, 800);
                  setBtnClick(!isbtnClicked);
                }}
              >
                Product
              </Link>
            </li>
            <li className='navlist-mobile'>
              <Link
                className={`navlink-mobile ${
                  btnActive === 'about' && 'active'
                }`}
                to='/#count-on-us'
                onClick={() => {
                  document.title = 'TowerBuddy » About';
                  setBtnActive('about');
                  document.querySelector('#count-on-us')?.scrollIntoView();
                  setTimeout(() => {
                    let windowY = window.pageYOffset;
                    window.scrollTo(0, windowY - 62);
                    document.querySelector('#header').classList.add('show-nav');
                  }, 800);
                  setBtnClick(!isbtnClicked);
                }}
              >
                About
              </Link>
            </li>
            <li className='navlist-mobile'>
              <Link
                className={`navlink-mobile ${
                  btnActive === 'contact' && 'active'
                }`}
                to='/'
                onClick={() => {
                  document.title = 'TowerBuddy » Contact';
                  setBtnActive('contact');
                  document.querySelector('#footer')?.scrollIntoView();
                  setTimeout(
                    () =>
                      document
                        .querySelector('#header')
                        .classList.add('show-nav'),
                    800
                  );
                  setBtnClick(!isbtnClicked);
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </Container>
  );
};

export default MobileNavbar;
