import './Navbar.css';
import logo from '../../assets/logo/TowerBuddy.png';
import Container from '../UI/Container';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../store/AppContext';
import MobileNavbar from './MobileNavbar';

const Navbar = () => {
  const { btnActive, setBtnActive, isTabletOrMobile } = useAppContext();

  return (
    <header
      id='header'
      className={`header show-nav ${
        isTabletOrMobile ? ['header-mobile'] : ''
      } `}
    >
      {isTabletOrMobile ? (
        <MobileNavbar />
      ) : (
        <Container className='container-navbar'>
          <div className='container-nav'>
            <Link to={'/'}>
              <img className='logo' src={logo} alt='tower buddy logo' />
            </Link>
            <nav className='nav-container'>
              <ul className='navlists'>
                <li className='navlist'>
                  <Link
                    className={`navlink ${btnActive === 'home' && 'active'}`}
                    to='/#home'
                    onClick={() => {
                      document.title = 'TowerBuddy';
                      setBtnActive('home');
                      document.querySelector('#home')?.scrollIntoView();
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className='navlist'>
                  <Link
                    className={`navlink ${btnActive === 'product' && 'active'}`}
                    to='/#our-offerings'
                    onClick={() => {
                      document.title = 'TowerBuddy » Product';
                      setBtnActive('product');
                      document
                        .querySelector('#our-offerings')
                        ?.scrollIntoView();
                      setTimeout(() => {
                        var windowY = window.pageYOffset;
                        window.scrollTo(0, windowY - 84);
                      }, 800);
                    }}
                  >
                    Product
                  </Link>
                </li>
                <li className='navlist'>
                  <Link
                    className={`navlink ${btnActive === 'about' && 'active'}`}
                    to='/#count-on-us'
                    onClick={() => {
                      document.title = 'TowerBuddy » About';
                      setBtnActive('about');
                      document.querySelector('#count-on-us')?.scrollIntoView();
                      setTimeout(() => {
                        var windowY = window.pageYOffset;
                        window.scrollTo(0, windowY - 84);
                      }, 800);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li className='navlist'>
                  <Link
                    className={`navlink ${btnActive === 'contact' && 'active'}`}
                    to='/'
                    onClick={() => {
                      document.title = 'TowerBuddy » Contact';
                      setBtnActive('contact');
                      document.querySelector('#footer')?.scrollIntoView();
                      setTimeout(() => {
                        document
                          .querySelector('#header')
                          .classList.add('show-nav');
                      }, 800);
                    }}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      )}
    </header>
  );
};

export default Navbar;
