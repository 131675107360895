import { createContext, useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const Context = createContext();

export default function AppContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [btnActive, setBtnActive] = useState('home');

  return (
    <Context.Provider
      value={{
        isTabletOrMobile,
        btnActive,
        setBtnActive,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useAppContext() {
  const appContext = useContext(Context);

  if (appContext === undefined)
    throw new Error('AppContext was used outside AppProvider');

  return appContext;
}
