/* eslint-disable no-unused-vars */
import { useCookies } from 'react-cookie';
import './CookieConsent.css';

export default function CookieConsent() {
  const [cookies, setCookie] = useCookies(['cookieConsentTB']);

  function giveCookieConsent() {
    setCookie('cookieConsentTB', true, { path: '/' });
  }

  return (
    <div className='cookies-container'>
      <p className=' cookies-paragraph'>
        We use cookies to enhance your browsing experience. By clicking
        <span className=' accept'> "Accept"</span>, you consent to our use of
        cookies.
      </p>
      <div className='cookies-container-btns'>
        <button className='btn-accept' onClick={() => giveCookieConsent()}>
          Accept
        </button>
        <button className='btn-reject' onClick={() => giveCookieConsent()}>
          Reject
        </button>
      </div>
    </div>
  );
}
