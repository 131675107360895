import React, { useState } from 'react';
import Container from '../UI/Container';
import Button from '../UI/Button';
import './PickCountryForms.css';

const PickCountryApp = () => {
  const [selected, setSelected] = useState('DEFAULT');

  function handleSelect(country) {
    switch (country) {
      case 'DRC':
        window.open(
          'https://play.google.com/store/apps/details?id=com.cod.i4sight',
          '_self'
        );
        break;
      case 'INDIA':
        window.open(
          'https://play.google.com/store/apps/details?id=com.ind.i4sight',
          '_self'
        );
        break;
      case 'NIGERIA':
        window.open(
          'https://play.google.com/store/apps/details?id=com.nga.i4sight',
          '_self'
        );
        break;
      case 'SOUTHSUDAN':
        window.open(
          'https://play.google.com/store/apps/details?id=com.ssd.i4sight',
          '_self'
        );
        break;
      case 'UGANDA':
        window.open(
          'https://play.google.com/store/apps/details?id=com.uga.i4sight',
          '_self'
        );
        break;
      case 'ZAMBIA':
        window.open(
          'https://play.google.com/store/apps/details?id=com.zmb.i4sight',
          '_self'
        );
        break;
      default:
        alert('Please select a country');
        break;
    }
  }

  return (
    <>
      <section id='pick-country'>
        <Container className='empty-box'>
          <h2 className='title-secondary'> Please select your country</h2>
          <div className='custom-select'>
            <select onChange={(e) => setSelected(e.target.value)}>
              <option value='DEFAULT'>Click here to select...</option>
              <option value='DRC'>Democratic Republic of Congo</option>
              <option value='INDIA'>India</option>
              <option value='NIGERIA'>Nigeria</option>
              <option value='SOUTHSUDAN'>South Sudan</option>
              <option value='UGANDA'>Uganda</option>
              <option value='ZAMBIA'>Zambia</option>
            </select>
            <Button
              name='Navigate'
              className='btn-navigate'
              type='button'
              onClick={() => handleSelect(selected)}
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default PickCountryApp;
