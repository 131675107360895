import React from "react";
import "./Svg.css";

const Svg = (props) => {
  return (
    <div className="svg">
      <svg
        className="svg-1"
        style={{ fill: props.color1 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 35 1440 200"
      >
        <path
          fillOpacity="1"
          d="M0,96L40,85.3C80,75,160,53,240,58.7C320,64,400,96,480,106.7C560,117,640,107,720,85.3C800,64,880,32,960,37.3C1040,43,1120,85,1200,96C1280,107,1360,85,1400,74.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
      <svg
        className="svg-2"
        style={{ fill: props.color2 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -10 1440 200"
      >
        <path
          fillOpacity="1"
          d="M0,32L48,37.3C96,43,192,53,288,48C384,43,480,21,576,37.3C672,53,768,107,864,112C960,117,1056,75,1152,58.7C1248,43,1344,53,1392,58.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <svg
        className="svg-3"
        style={{ fill: props.color3 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 110 1440 250"
      >
        <path
          fillOpacity="1"
          d="M0,192L40,197.3C80,203,160,213,240,208C320,203,400,181,480,192C560,203,640,245,720,240C800,235,880,181,960,170.7C1040,160,1120,192,1200,197.3C1280,203,1360,181,1400,170.7L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default Svg;
